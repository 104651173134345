<template>
  <div class="static-table">
    <el-table class="table" ref="multipleTable" highlight-current-row border :data="pager.data"
      @current-change="currHandler" @select="selectHandler" @sort-change="sortHandler" @selection-change="seleHandler" size="small">
      <template v-for="(col, index) in columns" :key="index" :prop="col.prop" :label="col.label">
        <el-table-column v-if="(col.type || '') === 'selection'" type="selection" :width="col.width || 55"
          align="center" />

        <el-table-column v-if="(col.type || '') === 'index'" :label="col.label" width="50" align="center">
          <template #default="scope">
            <span>{{ scope.$index + (pager.page - 1) * pager.rows + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="(col.type || '') === ''" :prop="col.prop" :label="col.label" :sortable="col.sortable"
          :width="col.width || 0" :align="col.align || 'left'" :show-overflow-tooltip="col.showOverflowTooltip || false">
        </el-table-column>

        <el-table-column v-if="(col.type || '') === 'template'" :prop="col.prop" :label="col.label"
          :sortable="col.sortable" :width="col.width || 0" :show-overflow-tooltip="col.showOverflowTooltip || false">
          <template #default="scope">
            <slot :name="col.prop" :row="scope.row"></slot>
          </template>
        </el-table-column>

        <el-table-column v-if="(col.type || '') === 'toolbar'" :label="col.label" :width="col.width || 0" fixed="right"
          align="center">
          <template #default="scope">
            <slot name="toolbar" :row="scope.row" :id="scope.row.id"></slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <!-- 
    <el-pagination :v-if="pager.page" background layout="sizes , prev, pager, next , total , jumper" :total="pager.records" class="pagination" :page-sizes="[10, 20, 50, 100]" @current-change="pageHandler" @size-change="sizeHandler">
    </el-pagination> -->
    <el-pagination class="pagination" :currentPage="pager.page" :page-size="pager.rows || pager.size || 10"
      :page-sizes="[10, 20, 50, 100, 200, 500]" layout="total, sizes, prev, pager, next, jumper"
      :total="pager.records || 0" background @size-change="sizeHandler" @current-change="pageHandler" />
  </div>
</template>
<script>
export default {
  components: {},
  name: "StaticTable",
  props: {
    pager: { type: Object, required: true },
    columns: { type: Object, required: true },
    checkedRows: { type: Object, required: false },
    currHandler: { type: Function, required: false }, //表格当前行
    seleHandler: { type: Function, required: false }, //多选事件
    sortHandler: { type: Function, required: false }, //表格列排序
    pageHandler: { type: Function, required: false }, //分页索引
    sizeHandler: { type: Function, required: false }, //分页数量
    selectHandler: { type: Function, required : false } //复选框选中事件 
  },
  data() {
    return {};
  },
  methods: {
    // setCheckeds: function (rows) {
    //   console.log(rows);
    //   let that = this;
    //   setTimeout(function () {
    //     rows.forEach(item => {
    //       that.$refs.multipleTable.toggleRowSelection(item);
    //     })
    //   }, 1000);
    // }
    // queryCheckeds: function () {
    //   let that = this;
    //   console.log('queryCheckeds:', this.checkedRows)
    //   setTimeout(function () {
    //     console.log('queryCheckeds--setTimeout:', that.checkedRows)
    //   }, 1000);
    // }
  },
  mounted() {
    // let that = this;
    // setInterval(() => {
    //   console.log('mounted:', that.checkedRows)
    // }, 2000);
  },
  watch: {
    checkedRows: function (newValue, oldValue) {
      console.log(newValue,oldValue)
      let that = this;
      setTimeout(() => {
        newValue.forEach(item => {
          that.$refs.multipleTable.toggleRowSelection(item);
        })
      });
    }
  }
};
</script>
<style scoped></style>