<template>
  <div class="static-table">
    <el-table class="table" ref="multipleTable" highlight-current-row border :data="query.data" size="small"
      @sort-change="sortHandler" @current-change="currHandler" @select="selectHandler" @selection-change="selectHandler">
      <template v-for="(col, index) in query.columns" :key="index" :prop="col.prop" :label="col.label">
        <el-table-column v-if="(col.type || '') === 'selection'" type="selection" :width="col.width || 55"
          align="center" />

        <el-table-column v-else-if="(col.type || '') === 'index'" :label="col.label" width="50" align="center">
          <template #default="scope">
            <span>{{ scope.$index + (query.page - 1) * query.rows + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column v-else-if="(col.type || '') === 'template'" :prop="col.prop" :label="col.label"
          :sortable="col.sortable" :width="col.width || 0" :show-overflow-tooltip="col.showOverflowTooltip || false">
          <template #default="scope">
            <slot :name="col.prop" :row="scope.row"></slot>
          </template>
        </el-table-column>

        <el-table-column v-else-if="(col.type || '') === 'toolbar'" :label="col.label" :width="col.width || 0"
          fixed="right" align="center">
          <template #default="scope">
            <slot name="toolbar" :row="scope.row" :id="scope.row.id"></slot>
          </template>
        </el-table-column>

        <el-table-column v-else="(col.type || '') === ''" :prop="col.prop" :label="col.label" :sortable="col.sortable"
          :width="col.width || 0" :align="col.align || 'left'"
          :show-overflow-tooltip="col.showOverflowTooltip || false">
        </el-table-column>
      </template>
    </el-table>
    <el-pagination class="pagination" :currentPage="query.page" :page-size="query.rows || query.size || 10"
      :page-sizes="[10, 20, 50, 100, 200, 500]" layout="total, sizes, prev, pager, next, jumper"
      :total="query.records || 0" background @size-change="pageSizeHandler" @current-change="pageChangHandler" />
  </div>
</template>
<script>
export default {
  components: {},
  name: "BasicTable",
  props: {
    query: { type: Object, required: true },
    //pageHandler: { type: Function, required: false }, //分页索引
  },
  emits: ["handler"],
  data() {
    return {
      //pager: { page: 1, rows: 10, records: 0 }
      chooseRow: null,
      // selectRow: null,
    };
  },
  methods: {
    sortHandler: function (order) {
      this.query.sidx = order.prop;
      this.query.sord = order.order;
      this.$emit('handler', this.query);
    },
    pageSizeHandler: function (rows) {
      this.query.rows = rows;
      this.$emit('handler', this.query);
    },
    pageChangHandler: function (page) {
      this.query.page = page;
      this.$emit('handler', this.query);
    },

    currHandler: function (row) {
      console.log('currentHandler', row);
      this.chooseRow = row;
      this.query.current = row;
      //this.$emit('currentHandler', this.query);
    },
    selectHandler: function (rows) {
      console.log('selectHandler', rows);
      //this.selectRow = rows;
      this.query.selects = rows;
      //this.$emit('handler', this.query);
    },
  },
  mounted() {
    //console.log('init', this.query)
  },
  watch: {
    query: function (newValue, oldValue) {
      console.log('basic-table',newValue);
      // let that = this;
      // if (newValue && newValue.selects)
      //   setTimeout(() => {
      //     newValue.selects.forEach(item => {
      //       that.$refs.multipleTable.toggleRowSelection(item);
      //     })
      //   });
    }
  }
};
</script>
<style scoped></style>