import util from '@/utils/util'
import request from '@/utils/request'


export function fetchDictTree(category) {
    return request({
        url: '/BaseDict/Tree?category=' + (category || 'dict'),
        method: 'get'
    });
}

export function submitDict(data) {
    return request({
        url: '/BaseDict/' + (data.id || ''),
        method: data.id ? 'put' : 'post',
        data: data
    });
}


export function fetchDictPager(params) {
    return request({
        url: '/BaseDict/Search?r=' + Math.random(),
        method: 'post',
        data: params
    })
}

export function delDict(dataID) {
    return request({
        url: '/BaseDict/' + (dataID || ''),
        method: 'delete'
    });
}


/**
 * 字典项
 */
export function dicts(category, code) {
    return request({
        url: '/BaseDict/List?v=' + util.getVersion(),
        params: {
            category: category || '',
            code: code || ''
        },
        method: 'get',
        cache: 60 * 60 * 24 * 7 //7天的缓存
    }).then((res) => {
        var options = [];
        res.data.forEach(function (item) {
            options.push({
                "name": item.name,
                "value": item.value,
                "code": item.code,
                "category": item.category,
                "attribute": item.attribute,
                "id": item.id
            });
        });
        return options;
    });
}




export function fetchFileList(dataID, fileCode) {
    return request({
        url: '/BaseUploader/Files?dataID=' + (dataID || '') + '&fileCode=' + (fileCode || ''),
        method: 'get'
    });
}
export function fetchFileDetail(id) {
    return request({
        url: '/BaseUploader/' + id,
        method: 'get'
    });
}

export function delFile(dataID) {
    if (dataID)
        return request({
            url: '/BaseUploader/' + (dataID || ''),
            method: 'delete'
        });
}


export function getCompanyTree() {
    return fetchCompanyTree(0);//不加缓存
}

export function fetchCompanyTree(cache) {
    var body = {
        url: '/BaseCompany/Tree?v=' + util.getVersion(),
        method: 'get'
    };
    let timer = cache && 60 * 60 * 24 * 7;
    if (timer > 0)
        body.cache = timer; //7天的缓存

    return request(body);
}

export function submitCompany(data) {
    return request({
        url: '/BaseCompany/' + (data.id || ''),
        method: data.id ? 'put' : 'post',
        data: data
    });
}


export function delCompany(dataID) {
    return request({
        url: '/BaseCompany/' + (dataID || ''),
        method: 'delete'
    });
}




export function getDepartmentTree(companyId) {
    return fetchDepartmentTree(companyId, 0);//不加缓存
}
export function fetchDepartmentTree(companyId, cache) {
    var body = {
        url: '/BaseDepartment/Tree?CompanyId=' + companyId + '&&v=' + util.getVersion(),
        method: 'get'
    };
    let timer = cache && 60 * 60 * 24 * 7;
    if (timer > 0)
        body.cache = timer; //7天的缓存

    return request(body);
}

export function submitDepartment(data) {
    return request({
        url: '/BaseDepartment/' + (data.id || ''),
        method: data.id ? 'put' : 'post',
        data: data
    });
}


export function delDepartment(dataID) {
    return request({
        url: '/BaseDepartment/' + (dataID || ''),
        method: 'delete'
    });
}



export function fetchMenus(category) {
    return request({
        url: '/BaseModule/TreeData?category=' + (category || '') + '&v=' + util.getVersion(),
        params: {},
        method: 'get'
    }).then((res) => {
        return res.data;
    });
}


export function fetchMenuDetail(id) {
    return request({
        url: '/BaseModule/' + id,
        method: 'get'
    });
}
export function submitMenu(data) {
    return request({
        url: '/BaseModule/' + (data.id || ''),
        method: data.id ? 'put' : 'post',
        data: data
    });
}
export function delMenu(dataID) {
    return request({
        url: '/BaseModule/' + (dataID || ''),
        method: 'delete'
    });
}
